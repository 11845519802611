import Swiper, {Navigation, Pagination, Autoplay, Controller} from "swiper";

Swiper.use([Navigation, Pagination, Autoplay, Controller]);

document.addEventListener("DOMContentLoaded", () => {
    // ====================__main slider__==================== //

    new Swiper(".main__container", {
        slidesPerView: 1,
        longSwipesRatio: 0.01,
        longSwipesMs: 100,
        observer: true,
        observeParents: true,
        observeSlideChildren: true,
        pagination: {
            el: ".swiper-pagination1",
            type: "bullets",
            clickable: true,
        },
        autoplay: {
            delay: 20000,
        },
    });

    // ====================__slider 1 view__==================== //

    new Swiper(".slider1", {
        // loop: true,
        slidesPerView: 1,
        observer: true,
        observeParents: true,
        preloadImages: false,
        navigation: {
            nextEl: ".next-button",
            prevEl: ".prev-button",
        },
        pagination: {
            el: ".swiper-pagination1",
            type: "bullets",
        },
        autoplay: {
            delay: 20000,
        },
        breakpoints: {
            992: {
                pagination: {
                    clickable: true,
                },
            },
        },
    });

    // ====================__main reviews__==================== //

    new Swiper(".s-reviews__container", {
        // loop: true,
        slidesPerView: 1,
        spaceBetween: 30,
        speed: 800,
        observer: true,
        observeParents: true,
        preloadImages: false,
        autoplay: {
            delay: 5000,
        },
        pagination: {
            el: ".swiper-pagination-reviews",
            type: "bullets",
        },
        breakpoints: {
            767: {
                slidesPerView: 2,
            },
            992: {
                slidesPerView: 3,
                pagination: {
                    clickable: true,
                },
            },
        },
    });

    // ====================__slider 3 view__==================== //

    new Swiper(".slider3", {
        // loop: true,
        slidesPerView: 1,
        spaceBetween: 30,
        speed: 800,
        observer: true,
        observeParents: true,
        preloadImages: false,
        autoplay: {
            delay: 5000,
        },
        pagination: {
            el: ".swiper-pagination",
            type: "bullets",
        },
        navigation: {
            nextEl: ".next-button",
            prevEl: ".prev-button",
        },
        breakpoints: {
            767: {
                slidesPerView: 2,
            },
            992: {
                slidesPerView: 3,
                pagination: {
                    clickable: true,
                },
            },
        },
    });

    // ====================__slider 4 view__==================== //

    new Swiper(".slider4", {
        //loop: true,
        slidesPerView: 1,
        spaceBetween: 30,
        observer: true,
        slideToClickedSlide: true,
        observeParents: true,
        navigation: {
            nextEl: ".next-button",
            prevEl: ".prev-button",
        },
        pagination: {
            el: ".swiper-pagination4",
            type: "bullets",
        },
        breakpoints: {
            1200: {
                slidesPerView: 4,
            },
            992: {
                slidesPerView: 3,
                pagination: {
                    clickable: true,
                },
            },
            576: {
                slidesPerView: 2,
            },
        },
    });
    // ====================__memories__==================== //
    const lifeSwiper = new Swiper(".life__container", {
        loop: false,
        slidesPerView: 1,
        slideToClickedSlide: true,
    });
    new Swiper(".memories-slider", {
        //loop: true,
        slidesPerView: 1,
        spaceBetween: 30,
        freeMode: false,
        slideToClickedSlide: true,
        watchSlidesVisibility: true,
        watchSlidesProgress: true,
        navigation: {
            nextEl: ".next-memories",
            prevEl: ".prev-memories",
        },
        breakpoints: {
            1200: {
                slidesPerView: 4,
            },
            992: {
                slidesPerView: 3,
            },
            576: {
                slidesPerView: 2,
            },
        },

        controller: {
            control: lifeSwiper,
        },
    });

    // ====================__s-how__slider__==================== //

    new Swiper(".s-how__slider1", {
        centeredSlides: true,
        loop: true,
        slidesPerView: "auto",
        spaceBetween: 10,
        speed: 250,
        updateOnWindowResize: true,
        direction: "vertical",
        observer: true,
        observeParents: true,
        observeSlideChildren: true,
        pagination: {
            el: ".swiper-pagination",
            type: "fraction",
        },
        navigation: {
            nextEl: ".next-button1",
            prevEl: ".prev-button1",
        },
    });

    new Swiper(".slider-memories-main", {
        // loop: true,
        slidesPerView: 1,
        observer: true,
        observeParents: true,
        preloadImages: false,
        freeMode: false,
        watchSlidesVisibility: true,
        watchSlidesProgress: true,
        // navigation: {
        //     nextEl: ".next-memories",
        //     prevEl: ".prev-memories",
        // },
        autoplay: {
            delay: 20000,
        },
    });

    new Swiper(".s-how__slider2", {
        direction: "vertical",
        loop: true,
        centeredSlides: true,
        updateOnWindowResize: true,
        slidesPerView: "auto",
        spaceBetween: 10,
        observer: true,
        observeParents: true,
        observeSlideChildren: true,
        autoplayDisableOnInteraction: true,
        pagination: {
            el: ".swiper-pagination",
            type: "fraction",
        },
        navigation: {
            nextEl: ".next-button2",
            prevEl: ".prev-button2",
        },
    });
});
